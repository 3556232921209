import React from 'react'
import { motion } from "framer-motion"

import { formatFileStackImage } from 'helpers/filestack'
import { CountdownTimer, Image, Panel, SlotIcon, Typography } from '@/components/ui'
import { VariantType } from 'hooks/useVariant'
import { SlotIconType } from '@/components/ui/SlotIcon'

import SaleItemTag from '../SaleItemTag'
import SaleItemDescription from '../SaleItemDescription'
import SaleItemBuyButton from '../SaleItemBuyButton'

import { SaleItemTileProps } from './types'
import s from './styles.module.css'

const SaleItemTile = (props: SaleItemTileProps) => {
  const { item } = props
  const {
    id,
    tags = [],
    description,
    saleUrl,
    saleStartDate,
    saleEndDate,
    amount,
    thumbnail,
    rarity,
    acceptedPayment,
    slot,
    chains
  } = item

  const itemImage = thumbnail.src ?? "./_assets/placeholder.webp"

  return (
    <motion.div
      id={id.toString()}
      className={s.sale_item_wrapper}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <Panel
        className={s.sale_item}>
        <div className={s.sale_item_image_container}>
          <Image
            src={formatFileStackImage(itemImage, {
              resize: {
                width: 450
              }
            })}
            alt=""
            width={450}
            height={300}
            rounded={false}
            className={s.sale_item_image}
            draggable={false}
            unoptimized
          />
          {
            thumbnail.label &&
            <Typography
              uppercase
              className={s.sale_item_image_text}>
              {thumbnail.label}
            </Typography>
          }
        </div>
        <div className={s.sale_item_body}>
          <div className={s.sale_item_details}>
            <div className={s.sale_item_tags}>
              {
                rarity &&
                <SaleItemTag
                  label={rarity === "any" ? "Variants" : rarity}
                  variant={rarity as VariantType}
                  className={s.sale_item_tag}
                  filled
                />
              }
              {
                tags.map((tag, index) => (
                  <SaleItemTag
                    key={index}
                    label={tag.label}
                    variant={tag.variant}
                    className={s.sale_item_tag}
                    color
                  />
                ))
              }
            </div>
            {
              slot &&
              <div className={s.sale_item_slot}>
                <SlotIcon
                  slot={slot as SlotIconType}
                />
              </div>
            }
          </div>
          <SaleItemDescription
            description={description.text}
            highlight={description.highlight}
            highlightVariant={description.variant}
          />
        </div>
        <CountdownTimer
          className={s.sale_status_message}
          countdownActivePrefix="Ends in: "
          countdownFinishedPrefix="Starts on"
          countdownOpenPrefix="Starts on"
          openBeforeSeconds={7200}
          endDate={saleEndDate}
          startDate={saleStartDate}
          displayNotStarted={true}
          displayOpen={true}
          displayFinished={true}
          timeEndingThresholdSeconds={3600}
          timeEndingClassName="!text-skin-primary"
          noTimeLeftMessage="Sale has ended!">
          {
            (state) => (
              <div className={s.sale_item_footer}>
                <SaleItemBuyButton
                  acceptedPayment={acceptedPayment}
                  amount={amount}
                  saleUrl={saleUrl}
                  endDate={saleEndDate}
                  startDate={saleStartDate}
                  state={state}
                  chains={chains}
                />
              </div>
            )
          }
        </CountdownTimer>
      </Panel>
    </motion.div>
  )
}

export default SaleItemTile 
