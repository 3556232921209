import React from 'react'
import cn from 'classnames'

import { formatFileStackImage } from 'helpers/filestack'
import { MarketplaceSaleItem } from '@/types/marketplace'
import { SlideItem, SlideItemContent } from '@/components/ui/Slideshow'
import SaleItemBuyButton from '../SaleItemBuyButton/SaleItemBuyButton'

import s from './styles.module.css'

const SaleItemFeaturedImageSlide = (props: MarketplaceSaleItem) => {
  const { images, id, contentAlignment = "bottom", contentPosition = "middle" } = props

  const itemClasses = cn({
    "text-center w-full": true,
    "!justify-end": contentPosition === "right",
    "!justify-start": contentPosition === "left",
  })

  const onClick = () => {
    if (document) {
      const tile = document.getElementById(`${id.toString()}`)

      if (tile) {
        const tileOffset = tile.getBoundingClientRect().top
        window.scrollTo({ behavior: "smooth", top: tileOffset - 120 })
      }
    }
  }

  return (
    <SlideItem
      blur={false}
      onClick={onClick}
      containContent={false}
      bodyClassName={itemClasses}
      className={s.slide_item}
      backgroundUrl={formatFileStackImage(images.featured, { resize: { width: 1920 } })} />
  )
}

export default SaleItemFeaturedImageSlide 
