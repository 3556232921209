
import React from 'react'
import { Image } from '@/components/ui'
import { ImageProps } from '@/components/ui/Image/types'

const PreAlphaPassImage = (props: Omit<ImageProps, "src">) => (
  <Image
    width={600}
    height={200}
    {...props}
    src="https://cdn.filestackcontent.com/output=format:webp/resize=height:200/g7M2OUq6QPy7RAD7wgL0"
    alt=""
  />
)

export default PreAlphaPassImage