import React, { useState } from 'react'

import { Button, Typography, Video } from '@/components/ui'

import { CustomTagPreviewProps } from './types'
import s from "./styles.module.css"

const PREVIEW_SRCS = {
  "George": "/_assets/marketplace/custom-tags/PlayerTag_George.mp4",
  "Nikki": "/_assets/marketplace/custom-tags/PlayerTag_Nikki.mp4",
}

const CustomTagPreview = (props: CustomTagPreviewProps) => {
  const { customTag } = props
  const avatars = Object.keys(PREVIEW_SRCS) as Array<keyof typeof PREVIEW_SRCS>
  const [previewAvatar, setPreviewAvatar] = useState<keyof typeof PREVIEW_SRCS>(avatars[0])

  return (
    <div className={s.preview_wrapper}>
      <div className={s.preview}>
        {
          avatars.map((avatar) => (
            <Video
              key={`video_${avatar}`}
              title={`In-game avatar ${avatar}`}
              width={500}
              height={500}
              muted
              loop
              autoPlay
              src={PREVIEW_SRCS[avatar]}
              className={avatar !== previewAvatar ? "hidden" : undefined}
            />
          ))
        }
        <Typography
          className={s.preview_tag}>
          {customTag ?? previewAvatar}
        </Typography>
      </div>
      <div className={s.preview_buttons}>
        {
          avatars.map((avatar) => (
            <Button
              key={`control_${avatar}`}
              onClick={() => setPreviewAvatar(avatar)}
              className={avatar === previewAvatar ? s.preview_button_active : undefined}
              variant="default"
              outline>
              {avatar}
            </Button>
          ))
        }
      </div>
    </div>
  )
}

export default CustomTagPreview
