import React, { useCallback, useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Router from 'next/router'

import { Button, ClientOnlyPortal, Loader, Modal, SimpleConfetti, StyledButton, Typography } from '@/components/ui'

import { useCreatePreAlphaAccessPayment } from 'mutations'
import { MarketplacePreAlphaCreatePaymentResponse } from '@/types/marketplace'
import { ROUTE_LOGIN_WITH_CALLBACK, ROUTE_GET_THE_GAME } from 'consts/routes'
import StripePayment, { StripePaymentForm } from '@/components/ui/StripePayment'
import { useStripePayment } from '@/providers/StripePaymentProvider'
import { useAuth } from '@/providers/AuthenticationProvider'
import { PLAYER_ROLE_EARLY_ACCESS_PASS } from 'consts/auth'
import { useGetPreAlphaPaymentStatus, usePlayerFullQuery } from 'queries'
import { HttpClientResponseError } from '@/network/httpClients'
import t from 'helpers/translation/getTranslation'
import buildPath from 'helpers/buildPath'
import { Gate } from '@/network/auth/gate'

import PreAlphaPassImage from '../PreAlphaPassImage'
import s from './styles.module.css'

type PaymentStatus = "pending" | "confirming" | "succeeded"

const PreAlphaPassPurchase = (props: { buttonText?: string }) => {
  const { buttonText = "Buy" } = props
  const { user, isLoggedIn, setUser } = useAuth()
  const { clientSecret, setClientSecret } = useStripePayment()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [payment, setPayment] = useState<MarketplacePreAlphaCreatePaymentResponse>()
  const [purchaseStatus, setPurchaseStatus] = useState<PaymentStatus>("pending")
  const hasEarlyAccessPass = Gate(user).allowsRole(PLAYER_ROLE_EARLY_ACCESS_PASS)
  const purchaseDisabled = hasEarlyAccessPass || purchaseStatus !== "pending"

  const { mutate: createPayment, isPending: isCreatingPayment } = useCreatePreAlphaAccessPayment()

  // We only want to use this once the payment has been confirmed therefore is disabled initially
  const { refetch: refetchPlayer, isError: isGetPlayerError } = usePlayerFullQuery(user?.id ?? 0, {
    enabled: false,
  })

  // Used to poll the payment status until payment is confirmed or failed
  const { data: paymentStatus, isRefetching } = useGetPreAlphaPaymentStatus(
    user?.sub ?? '',
    payment?.stripeId ?? '',
    {
      // Manually triggering refetch will enable this
      enabled: purchaseStatus === 'confirming',
      // Poll API when confirming payment only
      refetchInterval: purchaseStatus === 'confirming' ? 3000 : false,
    }
  )

  const handlePurchaseOnClick = useCallback(
    () => {
      if (isLoggedIn && user?.sub) {
        if (hasEarlyAccessPass) return

        if (clientSecret) {
          setModalIsOpen(true)
          return
        }

        createPayment({
          sub: user?.sub,
          email: user?.email
        },
          {
            onSuccess: (data) => {
              // Open modal, setup stripe form and show payment view
              setModalIsOpen(true)
              setClientSecret(data.stripeSecret)
              setPayment(data)
              setPurchaseStatus("pending")
            },
            onError(error) {
              if (error instanceof HttpClientResponseError) {
                if (error.isUnauthorized())
                  Router.push(ROUTE_LOGIN_WITH_CALLBACK(ROUTE_GET_THE_GAME))
              }
            },
          })
        return
      }

      Router.push(ROUTE_LOGIN_WITH_CALLBACK(ROUTE_GET_THE_GAME))
    },
    [user, hasEarlyAccessPass, isLoggedIn, clientSecret],
  )

  const onClosePayment = useCallback(async () => {
    setModalIsOpen(false)
  }, [setModalIsOpen])

  const onPaymentSubmitSuccess = useCallback(async () => {
    setPurchaseStatus("confirming")
  }, [setPurchaseStatus])

  useEffect(() => {
    // TODO:: Handle error
    switch (paymentStatus) {
      case "succeeded":

        // Prevent loop if there is an issue with player API
        if (isGetPlayerError) {
          if (window)
            window.location.reload()
          return
        }

        // Fetch the updated user data when payment is confirmed
        refetchPlayer().then(({ data: userData }) => {
          // We don't want to show the successful view until roles update
          if (userData && userData.playerUser.gameRoles.some((role) => role.name === PLAYER_ROLE_EARLY_ACCESS_PASS)) {
            setUser(userData.playerUser)
            setPurchaseStatus('succeeded')

            if (window) {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          }
        })
        break
    }
  }, [paymentStatus, refetchPlayer, isRefetching, isGetPlayerError])

  const getModalView = (status: PaymentStatus) => {
    switch (status) {
      case "succeeded":
        return (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className={s.modal_content}
          >
            <Typography
              className={s.modal_title}
              as="h2">
              {t("components.preAlphaPassPurchase.confirmed.modalTitle")}
            </Typography>
            <PreAlphaPassImage
              className={s.pre_alpha_pass_image}
            />
            <Typography
              className="!mb-4"
              as="p">
              {t("components.preAlphaPassPurchase.confirmed.modalThankYou")}
            </Typography>
            <Button
              onClick={() => setModalIsOpen(false)}
              outline>
              Close
            </Button>
            <ClientOnlyPortal selector='#modals'>
              <SimpleConfetti />
            </ClientOnlyPortal>
          </motion.div >
        )
      case "confirming":
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className={s.modal_content}
          >
            <Typography
              as="h2">
              {t("components.preAlphaPassPurchase.confirming.modalTitle")}
            </Typography>
            <Loader
              variant="primary"
              className={s.loader}
              size="md" />
            <Typography>
              {t("components.preAlphaPassPurchase.confirming.modalWait")}
            </Typography>
          </motion.div>
        )
      case "pending":
      default:
        return (
          <StripePayment>
            <StripePaymentForm
              returnUrl={buildPath(process.env.NEXT_PUBLIC_SITE_URL ?? "", ROUTE_GET_THE_GAME)}
              onPaymentSuccess={onPaymentSubmitSuccess} />
          </StripePayment>
        )
    }
  }

  return (
    <>
      {
        hasEarlyAccessPass ?
          <StyledButton
            as={"span"}
            hover={false}
            className={s.purchase_button}
            loading={isCreatingPayment}
            variant="filled"
            color={"success"}>
            {t("components.preAlphaPassPurchase.alreadyPurchasedLabel")}
          </StyledButton>
          :
          <StyledButton
            className={s.purchase_button}
            onClick={handlePurchaseOnClick}
            disabled={purchaseDisabled}
            loading={isCreatingPayment}
            variant="filled"
            color={"primary"}>
            {buttonText}
          </StyledButton>
      }
      <Modal
        showCloseButton={purchaseStatus === "pending"}
        onClickOutside={onClosePayment}
        onClose={onClosePayment}
        open={modalIsOpen}
        title={purchaseStatus === "pending" && t("components.preAlphaPassPurchase.pending.modalTitle")}
        size="md">
        <AnimatePresence mode="wait">
          {
            getModalView(purchaseStatus)
          }</AnimatePresence>
      </Modal>
    </>
  )
}

export default PreAlphaPassPurchase 
