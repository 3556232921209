import React from 'react'
import cn from 'classnames'

import { Pill } from '@/components/ui'

import { SaleItemTagProps } from './types'
import s from './styles.module.css'

const SaleItemTag = (props: SaleItemTagProps) => {
  const { label, variant, filled = false, color = true, className } = props

  return (
    <Pill
      key={label}
      className={cn(s.tag, variant && variant === "any" && s.variants, className)}
      outline
      filled={filled}
      color={color}
      variant={variant}
    >
      {label}
    </Pill>
  )
}

export default SaleItemTag
