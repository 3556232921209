import React, { PropsWithChildren } from 'react'
import {
  Elements
} from "@stripe/react-stripe-js";
import { useStripePayment } from '@/providers/StripePaymentProvider';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';

import LoadingWrapper from '../LoadingWrapper';

const StripePayment = (props: PropsWithChildren) => {
  const { children } = props
  const { clientSecret, loading, stripePromise } = useStripePayment()

  const appearance = {
    theme: 'night'
  } as Appearance

  const options = {
    clientSecret,
    appearance

  } as StripeElementsOptions

  return (
    <LoadingWrapper isLoading={loading}>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          {children}
        </Elements>
      )}
    </LoadingWrapper>
  )
}

export default StripePayment