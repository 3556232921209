import React from 'react'
import cn from 'classnames'

import { BlockchainCurrencyIcon, DropdownButton, MarketplaceLink, Typography } from '@/components/ui'
import { CountdownTimeState } from '@/components/ui/CountdownTimer/types'
import { BlockchainCurrencyType } from '@/components/ui/BlockchainCurrencyIcon'

import { SaleItemBuyButtonProps } from './types'
import s from './styles.module.css'

const SaleItemBuyButton = (props: SaleItemBuyButtonProps) => {
  const { amount, saleUrl, state, acceptedPayment = [], chains = [], currencySymbol = "$", currencyText = "USD", showComingSoon = true } = props

  const hasAmount = amount !== null && amount !== undefined

  return (
    <div className={s.sale_pricing}>
      {
        hasAmount &&
        <div className={s.sale_price_label}>
          {
            amount > 0 &&
            <Typography
              uppercase
              variant="secondary"
              className={s.sale_price_prefix}>
              {currencySymbol}
            </Typography>
          }
          <Typography
            className={s.sale_price}>
            {amount === 0 ? "Free" : amount}
          </Typography>
          {
            amount > 0 &&
            <Typography
              uppercase
              variant="secondary"
              className={s.sale_price_suffix}>
              {currencyText}
            </Typography>
          }
          {
            acceptedPayment && acceptedPayment.length > 0 &&
            <div className={s.sale_price_accepted_currency}>
              {
                acceptedPayment.map(payment => (
                  <BlockchainCurrencyIcon
                    key={payment}
                    className={payment as BlockchainCurrencyType === "copi" ? s.sale_copi_icon : undefined}
                    currency={payment as BlockchainCurrencyType}
                    chains={chains}
                  />
                ))
              }
            </div>
          }
        </div>
      }

      {
        state === CountdownTimeState.NotStarted &&
        showComingSoon && <div
          className={s.sale_coming_soon}>
          <Typography
            variant="black">
            Coming Soon
          </Typography>
        </div>
      }
      {
        (state === CountdownTimeState.Active || state === CountdownTimeState.Open || state === CountdownTimeState.Finished) &&
        <MarketplaceLink
          target="_blank"
          size="sm"
          saleSlug={saleUrl}>
          View Sale
        </MarketplaceLink>
      }
    </div>
  )
}

export default SaleItemBuyButton 
