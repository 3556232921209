import React from 'react'
import { motion, AnimatePresence } from "framer-motion"

import { Alert } from '@/components/ui'
import SaleItemTile from '../SaleItemTile'

import { SaleItemGridProps } from './types'
import s from './styles.module.css'

const SaleItemGrid = (props: SaleItemGridProps) => {
  const { items = [] } = props

  if (items.length === 0)
    return (
      <Alert className="mt-0">
        No items to display
      </Alert>
    )

  return (
    <AnimatePresence>
      <motion.div
        className={s.sale_item_grid}>
        {
          items.map((item) => (
            <SaleItemTile
              key={item.id}
              item={item}
            />
          ))
        }
      </motion.div>
    </AnimatePresence>
  )
}

export default SaleItemGrid 
