import React from 'react'
import cn from 'classnames'

import { HighlightedText, Typography } from '@/components/ui'
import useVariant from 'hooks/useVariant'

import { SaleItemDescriptionProps } from './types'
import s from './styles.module.css'

const SaleItemDescription = (props: SaleItemDescriptionProps) => {
  const { description, className, highlight, highlightVariant } = props

  const highlightClass = useVariant({
    variant: highlightVariant,
    color: true
  })

  return (
    <Typography
      as="p"
      className={cn(s.description, className)}>
      <HighlightedText
        text={description}
        highlight={highlight}
        className={cn(highlightClass, "font-bold")}
      />
    </Typography>
  )
}

export default SaleItemDescription 
