import React, { useState, useEffect, FormEvent } from 'react'

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { useStripePayment } from '@/providers/StripePaymentProvider';
import t from 'helpers/translation/getTranslation';

import StyledButton from '../../StyledButton';
import LoadingWrapper from '../../LoadingWrapper';
import Alert from '../../Alert';

import { StripePaymentFormProps, StripePaymentStatusType } from './types'
import s from './styles.module.css'

const StripePaymentForm = (props: StripePaymentFormProps) => {
  const { onPaymentSuccess, onPrePaySubmit, returnUrl } = props
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [message, setMessage] = useState<StripePaymentStatusType>()
  const { completeIntent, clientSecret } = useStripePayment()
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage({ message: "Payment succeeded!", type: "info" });
          break;
        case "processing":
          setMessage({ message: "Your payment is processing.", type: "info" });
          break;
        case "requires_payment_method":
          break;
        default:
          setMessage({ message: t("errors.genericContactAdmin") as string, type: "error" });
          break;
      }
    }).then(() => {
      setIsLoading(false)
    })

  }, [stripe, clientSecret])

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (onPrePaySubmit && onPrePaySubmit() === false)
      return;

    setIsLoading(true)

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: returnUrl,
        },
        redirect: 'if_required'
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error && (error.type === "card_error" || error.type === "validation_error")) {
        setMessage({ message: error.message || t("errors.genericContactAdmin") as string, type: "error" });
      }
      else if (paymentIntent?.status === "succeeded") {
        completeIntent()

        if (onPaymentSuccess)
          onPaymentSuccess()
      } else {
        setMessage({ message: t("errors.genericContactAdmin") as string, type: "error" });
      }
    } catch (error) {
      setMessage({ message: t("errors.genericContactAdmin") as string, type: "error" });
    }

    setIsLoading(false)
  }

  // We need render the Payment Element hidden otherwise it won't work correctly.
  return (
    <LoadingWrapper
      loaderClassName={s.loader_wrapper}
      size="md"
      renderChildrenHidden
      isLoading={isLoading}>
      <form
        id="payment-form"
        className={s.puchase_form}
        onSubmit={handleSubmit}>
        {
          message &&
          <Alert type={message.type}>{message.message}</Alert>
        }
        <PaymentElement
          id="payment-element"
          options={{
            layout: "tabs"
          }} />
        <div className={s.purchase_button_container}>
          <StyledButton
            disabled={isLoading || !stripe || !elements}
            className={s.purchase_button}
            variant="filled"
            color="primary">
            {t("pages.customTags.confirmPaymentButton")}
          </StyledButton>
        </div>
      </form>
    </LoadingWrapper>
  )
}

export default StripePaymentForm