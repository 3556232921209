import React from 'react'

import { StyledButton, Typography } from '@/components/ui'
import t from 'helpers/translation/getTranslation'

import CustomTagPreview from '../CustomTagPreview'
import s from "../styles.module.css"

const CustomTagsOverview = () => (
  <div className={s.wrapper}>
    <div className={s.col_1}>
      <CustomTagPreview />
    </div>
    <div className={s.col_2}>
      <Typography
        uppercase
        as="h4">
        {t("pages.customTags.whatIsACustomTagTitle")}
      </Typography>
      <Typography
        className="!mb-8"
        as="p">
        {t("pages.customTags.whatIsACustomTagDescription")}
      </Typography>
      <Typography
        uppercase
        as="h4">
        {t("pages.customTags.benefitsTitle")}
      </Typography>
      <Typography
        className="!mb-8"
        as="p">
        {t("pages.customTags.benefitsDescription")}
      </Typography>
      <ul className="list-disc list-inside mb-10">
        <li>To be confirmed</li>
        <li>To be confirmed</li>
        <li>To be confirmed</li>
      </ul>
      <div className="text-center">
        <StyledButton
          className={s.login_button}
          variant="filled"
          color="primary"
          as='a'
          href='/login?callbackUrl=/marketplace/custom-tags'>
          {t("pages.customTags.signInButton")}
        </StyledButton>
      </div>
    </div>
  </div>
)

export default CustomTagsOverview
